import React from 'react'
import { withStyles, Grid, Divider, Typography } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

const styles = theme => ({
  danger: {
    backgroundColor: red[200],
    border: '2px solid',
    borderRadius: '5px',
    borderColor: red[700],
    color: red[700],
    textAlign: 'center',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})
export const DangerZone = withStyles(styles)(({ classes, children }) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} className={classes.danger}>
        <Typography component="h1" variant="h6" color="inherit">
          Zone of danger
        </Typography>
        {children}
      </Grid>
    </React.Fragment>
  )
})
