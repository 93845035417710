import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React, { memo } from 'react'

const CategoryField = memo(({ classes, value, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="cat-simple">Category</InputLabel>
      <Select
        value={value}
        onChange={handleChange('category')}
        inputProps={{
          id: 'cat-simple',
        }}
      >
        <MenuItem value="book">book</MenuItem>
      </Select>
    </FormControl>
  )
})

export default CategoryField
