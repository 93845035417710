import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'
import MaterialTable from 'material-table'
import React from 'react'
import { withRouter } from 'react-router-dom'

const pageTitle = 'Episodes'
const columns = [
  { title: 'Title', field: 'title' },
  { title: 'Free', field: 'isFree', type: 'boolean' },
  {
    title: 'Published',
    field: 'state',
    type: 'boolean',
    render: rowData => (
      <Checkbox checked={rowData.state === 'published'} disabled />
    ),
  },
]
const options = {
  sorting: true,
  columnsButton: true,
  exportButton: true,
  // filtering: true,
  emptyRowsWhenPaging: false,
  pageSize: 10,
  actionsColumnIndex: -1,
}

// The pagination component is not right with rtl
const components = {
  Pagination: props => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

const EpisodeTable = props => {
  const { history, data } = props

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit game',
      onClick: (e, row) => handleEdit(e, row),
    },
  ]

  const handleEdit = (e, rowData) => {
    history.push(`/episodes/${rowData.slug}`)
  }

  return (
    <React.Fragment>
      <MaterialTable
        columns={columns}
        data={data}
        title={pageTitle}
        options={options}
        components={components}
        actions={actions}
      />
    </React.Fragment>
  )
}

export default withRouter(EpisodeTable)
