import React from 'react'
import { withRouter } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab'
import { CircularProgress } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Helper from '../../utils/Helper'
import { withDrawerContext } from '../../contexts/drawer-context'
import { Paper, Fab } from '@material-ui/core'

import { withSnackbar } from 'notistack'
import { doAndSnack, snackProgressBar } from '../../utils/snackbar'

import { useState } from 'react'
import EpisodeTable from '../../components/EpisodeTable'
import { useEffect } from 'react'
import SeriesForm from '../../components/SeriesForm'

const styles = theme => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
  fab: {
    top: 'auto',
    right: '3rem',
    bottom: '2rem',
    left: 'auto',
    position: 'fixed',
  },
})

const SeriesDetail = props => {
  const { classes, history, location, enqueueSnackbar } = props

  // const { slug } = props.match.params

  const [tabValue, setTabValue] = useState(0)
  const [episodes, setEpisodes] = useState([])
  const [title, setTitle] = useState('Title')
  const [series, setSeries] = useState(location.state)
  const [loading, setLoading] = useState(false)

  const fetchEpisodes = async () => {
    try {
      setLoading(true)
      const result = await Helper.ApiRequest(`/episodes`)
      const filtered = result.filter(
        data => data.series && data.series._id === series.id
      )
      setEpisodes(filtered)
      setLoading(false)
    } catch (error) {
      console.log(error)
      snackProgressBar(
        enqueueSnackbar,
        {
          success: '',
          fail: 'Failed to fetch data. Please refresh the page',
        },
        false
      )
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEpisodes()

    setTitle(series && series.title)
  }, [])

  const handleChangeTab = (e, value) => {
    setTabValue(value)
  }

  const saveSeries = () => {
    try {
      const promise = Helper.ApiRequest(`/series/${series.id}`, {
        method: 'PUT',
        body: JSON.stringify(series),
      })

      const onSuccess = () => {
        history.push('/series')
      }

      doAndSnack(promise, enqueueSnackbar, {
        success: 'Series succesfully saved',
        fail: 'Failed to save series',
        onSuccess,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = series => {
    setSeries({
      ...series,
    })
  }

  const addEpisode = () => {
    history.push(`/episodes/add?series=${series.slug}`)
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        {title}
      </Typography>
      {!loading ? (
        <Paper className={classes.paper}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            //   action={this.getAction}
          >
            <Tab label="Details" />
            <Tab label="Episodes" />
          </Tabs>

          {tabValue === 0 && (
            <TabContainer>
              <SeriesForm
                series={series}
                onChange={handleChange}
                onSave={saveSeries}
              />
            </TabContainer>
          )}
          {tabValue === 1 && (
            <EpisodeTab episodes={episodes} onAdd={addEpisode} />
          )}
        </Paper>
      ) : (
        <CircularProgress className={classes.progress} />
      )}
    </React.Fragment>
  )
}

export default withSnackbar(
  withDrawerContext(withRouter(withTheme()(withStyles(styles)(SeriesDetail))))
)

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

const EpisodeTab = withStyles(styles)(({ episodes, classes, e, onAdd }) => {
  return (
    <TabContainer>
      <EpisodeTable data={episodes} />
      <Fab
        color="secondary"
        aria-label="Add"
        className={classes.fab}
        onClick={onAdd}
      >
        <AddIcon />
      </Fab>
    </TabContainer>
  )
})
