import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import { withRouter } from 'react-router-dom'
import GameForm from '../../components/GameForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'

const styles = theme => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
})

class GameAdd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // default values for game
      game: {
        category: 'game',
        referenceSource: 'episodes',
      },
    }
  }
  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const { game } = this.state
    // The episode may be given in the url
    const { search } = this.props.location
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    })
    const { episode: episodeParam } = params

    const [episode] = episodeParam
      ? await Helper.ApiRequest(`/episodes?slug=${episodeParam}`)
      : []
    game.reference = episode
    this.setState({ game })
  }

  saveGame = () => {
    const { game } = this.state
    const { enqueueSnackbar } = this.props
    const [promise, onSuccess] = this.getSaveRequest(game)
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Game succesfully saved',
      fail: 'Failed to save game',
      onSuccess,
    })
  }

  getSaveRequest = game => {
    const { history } = this.props
    const epslug = game.reference.slug
    const path = '/activities'
    const method = 'POST'
    const body = JSON.stringify(game)
    const promise = Helper.ApiRequest(path, { method, body })

    const onSuccess = () => {
      history.push(`/episodes/${epslug}/games`)
    }
    return [promise, onSuccess]
  }
  handleChange = game => {
    this.setState({ game })
  }
  render() {
    const { classes } = this.props
    const { game } = this.state
    return (
      <React.Fragment>
        <FormTitle />
        <Paper className={classes.paper}>
          <GameForm
            onChange={this.handleChange}
            game={game}
            onSave={this.saveGame}
          />
        </Paper>
      </React.Fragment>
    )
  }
}

GameAdd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withRouter(withStyles(styles)(GameAdd)))

function FormTitle() {
  return (
    <Typography component="a" variant="h4" align="center">
      Add a game
    </Typography>
  )
}
