import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
})

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: null,
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    })
  }

  render() {
    const { classes, panels } = this.props
    const { expanded } = this.state

    return (
      <div className={classes.root}>
        {panels.map((panel, index) => {
          const { title, subtitle, content: Content, contentProps } = panel
          const panelName = `panel${index}`
          return (
            <ExpansionPanel
              key={panelName}
              expanded={expanded === panelName}
              onChange={this.handleChange(panelName)}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{title}</Typography>
                <Typography className={classes.secondaryHeading}>
                  {subtitle}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Content {...contentProps} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })}
      </div>
    )
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ControlledExpansionPanels)
