import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinkIcon from '@material-ui/icons/Link'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withEpisodes } from '../../contexts/data-context'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import GameForm from '../../components/GameForm'

const styles = theme => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
})

class ChapterDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      game: null,
    }
  }
  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const { id } = this.props.match.params
    const game = await Helper.ApiRequest(`/activities/${id}?$select[]=category&$select[]=content&$select[]=description&$select[]=isFree&$select[]=reference&$select[]=referenceSource&$select[]=state&$select[]=thumbnail&$select[]=title&$select[]=_id`)
    this.setState({ game })
  }

  save = () => {
    const { game } = this.state
    const { enqueueSnackbar } = this.props
    const [promise, onSuccess] = this.getSaveRequest(game)
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Game succesfully saved',
      fail: 'Failed to save game',
      onSuccess,
    })
  }

  getSaveRequest = game => {
    const { history } = this.props
    const { _id } = game
    const path = `/activities/${_id}`
    const method = 'PUT'
    const body = JSON.stringify(game)
    const promise = Helper.ApiRequest(path, { method, body })

    const onSuccess = () => {
      history.push(`/games/${_id}`)
      this.fetchData()
    }
    return [promise, onSuccess]
  }
  handleChange = game => {
    this.setState({ game })
  }
  render() {
    const { classes } = this.props
    const { game } = this.state
    if (!game) return null
    return (
      <React.Fragment>
        <FormTitle
          reference={game.reference}
          referenceSource={game.referenceSource}
        />
        <Paper className={classes.paper}>
          <GameForm
            game={game}
            onChange={this.handleChange}
            onSave={this.save}
          />
        </Paper>
      </React.Fragment>
    )
  }
}

ChapterDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(
  withEpisodes(withRouter(withStyles(styles)(ChapterDetail)))
)

function FormTitle({ reference, referenceSource }) {
  const { title, slug } = reference || {}
  let refName, link
  switch (referenceSource) {
    case 'episodes':
      refName = 'Episode'
      link = `/episodes/${slug}/games`
      break
    case 'chapters':
      refName = 'Chapter'
      link = `/chapters/${slug}`
      break
    default:
      return null
  }
  return (
    <Typography component="h2" variant="h4" align="center">
      {refName} Title : {title}
      <Link to={link}>
        <LinkIcon />
      </Link>
    </Typography>
  )
}
