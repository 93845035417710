import {
  Button,
  TextField,
  withStyles,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Grid,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import React, { memo } from 'react'

const styles = theme => ({
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 20,
  },
})

const ltrStyle = { style: { direction: 'ltr' } }

export const SaveButton = withStyles(styles)(({ classes, onClick }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      color="secondary"
      size="large"
      className={classes.button}
      onClick={onClick}
    >
      <SaveIcon className={classes.buttonIcon} />
      Save
    </Button>
  )
})

export const CancelButton = withStyles(styles)(({ classes, onClick }) => {
  return (
    <Button
      variant="text"
      color="default"
      size="large"
      className={classes.button}
      onClick={onClick}
    >
      <CancelIcon className={classes.buttonIcon} />
      Cancel
    </Button>
  )
})

export const DeleteButton = withStyles(styles)(({ classes, onClick }) => {
  return (
    <Button
      variant="outlined"
      color="inherit"
      size="large"
      className={classes.button}
      onClick={onClick}
    >
      <DeleteIcon className={classes.buttonIcon} />
      Delete
    </Button>
  )
})

export const OutlinedField = ({ ltr, ...props }) => {
  if (ltr) return <LtrTextField margin="normal" variant="outlined" {...props} />
  return <TextField margin="normal" variant="outlined" {...props} />
}

export const LtrTextField = props => {
  return (
    <TextField
      inputProps={ltrStyle}
      FormHelperTextProps={ltrStyle}
      {...props}
    />
  )
}

export const SvgField = ({ onChange, value, label }) => {
  return (
    <Grid container item>
      <Grid item xs={10}>
        <LtrTextField
          label={label}
          margin="dense"
          value={value}
          fullWidth
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={2}>
        <img alt="" src={value} />
      </Grid>
    </Grid>
  )
}

export const NumberField = ({ label, className, value = 0, onChange }) => {
  return (
    <LtrTextField
      label={label}
      margin="dense"
      type="number"
      className={className}
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

export const ChapterField = withStyles(styles)(
  memo(({ chapters, classes, id, onChange, disabled }) => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="ch-simple">Chapter</InputLabel>
        <Select
          disabled={disabled}
          value={id}
          onChange={onChange}
          inputProps={{
            id: 'ch-simple',
          }}
        >
          {chapters.map(ch => {
            return (
              <MenuItem key={ch._id} value={ch._id}>
                {ch.title}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  })
)

export const EpisodeField = withStyles(styles)(
  memo(({ episodes, classes, id, onChange, disabled }) => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="episode-simple">Episode</InputLabel>
        <Select
          disabled={disabled}
          value={id}
          onChange={onChange}
          inputProps={{
            id: 'episode-simple',
          }}
        >
          {episodes.map(ep => {
            return (
              <MenuItem key={ep._id} value={ep._id}>
                {ep.title}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  })
)

export const LevelField = withStyles(styles)(
  memo(({ paths, classes, id, onChange, disabled }) => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="path-simple">Level</InputLabel>
        <Select
          disabled={disabled}
          value={id}
          onChange={onChange}
          inputProps={{
            id: 'path-simple',
          }}
        >
          {paths.map(p => {
            return (
              <MenuItem key={p._id} value={p._id}>
                {p.title}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  })
)

// Choose between chapters and episodes
export const TypeField = withStyles(styles)(
  memo(({ classes, value, onChange, types }) => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="refSource-simple">Type</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          inputProps={{
            id: 'refSource-simple',
          }}
        >
          {types.map(t => (
            <MenuItem key={t} value={t}>
              {t}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  })
)

// Displays paths, episodes or chapters depending on the referenceSource
export const ReferenceField = memo(
  ({
    classes,
    onChange,
    reference,
    referenceSource,
    chapters,
    episodes,
    paths,
  }) => {
    const props = {
      id: reference ? (reference._id ? reference._id : reference) : '',
      onChange,
      classes,
    }
    switch (referenceSource) {
      case 'chapters':
        return <ChapterField chapters={chapters} {...props} />
      case 'episodes':
        return <EpisodeField episodes={episodes} {...props} />
      case 'paths':
        return <LevelField paths={paths} {...props} />
      default:
        return null
    }
  }
)
