import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { withSnackbar } from 'notistack'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { withPaths } from '../contexts/data-context'
import CardControl from './ImageControl'
import { DangerZone } from './Grids'
import { CancelButton, DeleteButton, OutlinedField, SaveButton } from './Forms'

import React from 'react'
import { DeleteSeries } from './Delete'

const styles = theme => ({
  root: {
    flexGrow: 1,
    // textAlign: 'center',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  listTextField: {
    width: 'calc(100% - 50px)', // We give place to the icon
    marginTop: '0',
    marginBottom: '0',
    paddingTop: '0',
  },
})

const SeriesForm = props => {
  const { history, classes, series, paths, onSave, onChange } = props

  const {
    title,
    slug,
    order,
    state,
    path,
    isFree,
    image,
    thumbnail,
    description,
  } = props.series

  const handleChange = field => event => {
    series[field] = event.target.value
    onChange(series)
  }

  const handleChangeLevel = event => {
    series.path = paths.find(e => e._id === event.target.value)
    onChange(series)
  }

  const handleChangeChecked = field => event => {
    series[field] = event.target.checked
    onChange(series)
  }

  const handleChangeUrl = field => value => {
    series[field] = value
    onChange(series)
  }

  const handleUploadThumbnail = () => {
    onSave()
  }

  const handleCancel = () => {
    history.push('/series')
  }

  const handleSave = async e => {
    e.preventDefault()
    return onSave()
  }

  const handleAfterDelete = () => {
    history.push(`/series`)
  }

  const checkProps = { classes, handleChange: handleChangeChecked }

  const textProps = { classes, handleChange: handleChange }
  const pathProps = {
    classes,
    onChange: handleChangeLevel,
    paths,
    id: path && path._id ? path._id : '',
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container spacing={24} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={2}>
          <TitleField {...textProps} value={title} />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <SlugField {...textProps} value={slug} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <OrderField {...textProps} value={order} />
        </Grid>

        <Grid item xs={12} sm={3} md={2}>
          <LevelField {...pathProps} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <StateField {...textProps} value={state} />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <IsFreeField {...checkProps} isFree={isFree} />
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container direction="column">
            <DescriptionField {...textProps} value={description} />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h6" variant="h6">
            Thumbnail Image
          </Typography>
          <CardControl
            url={thumbnail}
            title="Thumbnail"
            onUpload={handleUploadThumbnail}
            onChangeUrl={handleChangeUrl('thumbnail')}
            uploadDefaultPrefix="thumbs"
            uploadDefaultName={`th-${slug}`}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Typography component="h6" variant="h6">
            Background Image
          </Typography>
          <CardControl
            url={image}
            title="Background"
            onUpload={handleUploadThumbnail}
            onChangeUrl={handleChangeUrl('image')}
            uploadDefaultPrefix="bgs"
            uploadDefaultName={`bg-${slug}`}
          />
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12} sm={3} md={2}>
            <SaveButton onClick={handleSave} />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <CancelButton onClick={handleCancel} />
          </Grid>
        </Grid>
        {series._id && (
          <DangerZone>
            <DeleteSeries
              series={series}
              onAfterDelete={handleAfterDelete}
              component={DeleteButton}
            />
          </DangerZone>
        )}
      </Grid>
    </form>
  )
}

export default withPaths(
  withSnackbar(withRouter(withStyles(styles)(SeriesForm)))
)

const DescriptionField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Description"
      className={classes.textField}
      value={value}
      onChange={handleChange('description')}
      multiline
      // rowsMax="4"
      rows="4"
    />
  )
}

const TitleField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Title"
      className={classes.textField}
      value={value}
      onChange={handleChange('title')}
      required
    />
  )
}

const SlugField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Slug"
      className={classes.textField}
      value={value}
      onChange={handleChange('slug')}
      required
    />
  )
}

const OrderField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Order"
      className={classes.textField}
      type="number"
      value={value}
      onChange={handleChange('order')}
      required
    />
  )
}

const LevelField = ({ classes, id, paths, onChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="level-simple">Level</InputLabel>
      <Select
        value={id}
        onChange={onChange}
        inputProps={{
          id: 'level-simple',
        }}
      >
        {paths.map(p => {
          return (
            <MenuItem key={p._id} value={p._id}>
              {p.title}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const StateField = ({ classes, value, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="state-simple">State</InputLabel>
      <Select
        value={value}
        onChange={handleChange('state')}
        inputProps={{
          id: 'state-simple',
        }}
      >
        <MenuItem value="published">Published</MenuItem>
        <MenuItem value="draft">Draft</MenuItem>
      </Select>
    </FormControl>
  )
}
const IsFreeField = ({ classes, isFree, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel
        control={
          <Switch
            checked={isFree}
            onChange={handleChange('isFree')}
            value="isFree"
          />
        }
        label="Free"
      />
    </FormControl>
  )
}
