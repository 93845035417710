export async function doAndSnack(promise, enqueueSnackbar, options) {
  const { success = 'Success', fail = 'Fail', onSuccess } = options
  try {
    const res = await promise
    enqueueSnackbar(success, {
      variant: 'success',
    })
    if (onSuccess) onSuccess(res)
  } catch (error) {
    console.error(error)
    enqueueSnackbar(fail, {
      variant: 'error',
    })
  }
}

export function snackProgressBar(enqueueSnackbar, options, isSuccess) {
  const { success = 'Success', fail = 'Fail' } = options
  if (isSuccess) {
    enqueueSnackbar(success, {
      variant: 'success',
    })
  } else {
    enqueueSnackbar(fail, {
      variant: 'error',
    })
  }
} 
    
