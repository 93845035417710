import React from 'react'
import { withRouter } from 'react-router-dom'
import MaterialTable from 'material-table'
import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'
import Helper from '../../utils/Helper'

const pageTitle = 'Episodes'
const columns = [
  { title: 'Level', field: 'level' },
  { title: 'Series', field: 'seriesName' },
  { title: 'Title', field: 'title' },
  { title: 'Order', field: 'order' },
  { title: 'Chapters', field: 'nbChapters' },
  { title: 'Games', field: 'nbActivities' },
  {
    title: 'Published',
    field: 'state',
    type: 'boolean',
    render: rowData => (
      <Checkbox checked={rowData.state === 'published'} disabled />
    ),
  },
]
const options = {
  columnsButton: true,
  exportButton: true,
  filtering: true,
  emptyRowsWhenPaging: false,
  pageSize: 10,
  actionsColumnIndex: -1,
}

// The pagination component is not right with rtl
const components = {
  Pagination: props => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

class Episodes extends React.Component {
  state = {
    data: [],
    isLoading: false,
  }
  actions = [
    {
      icon: 'edit',
      tooltip: 'Edit episode',
      onClick: (e, row) => this.handleEdit(e, row),
    },
  ]
  async componentDidMount() {
    const data = await Helper.ApiRequest('/episodes')
    this.setState({ data })
  }

  handleEdit = (e, rowData) => {
    this.props.history.push(`/episodes/${rowData.slug}`)
  }

  render() {
    const { data } = this.state

    return (
      <MaterialTable
        columns={columns}
        data={data}
        title={pageTitle}
        options={options}
        components={components}
        actions={this.actions}
      />
    )
  }
}

export default withRouter(Episodes)
