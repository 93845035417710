import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { withRouter } from 'react-router-dom'
import { withChapters, withEpisodes } from '../contexts/data-context'
import Helper from '../utils/Helper'
import { DeleteGame } from './Delete'
import { CancelButton, DeleteButton, OutlinedField, SaveButton } from './Forms'
import GameFrame from './GameFrame'
import { DangerZone } from './Grids'
import CardControl from './ImageControl'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
})

class GameForm extends React.Component {
  handleCancel = () => {
    this.props.history.goBack()
  }

  handleSave = async e => {
    // We don't want submit to refresh the page
    e.preventDefault()
    const { onSave } = this.props
    return onSave()
  }
  handleAfterDelete = () => {
    const { history, game } = this.props
    history.push(`/episodes/${game.reference.slug}/games`)
  }

  handleChangeReference = event => {
    const { game, onChange, episodes, chapters } = this.props

    const byId = e => e._id === event.target.value
    switch (game.referenceSource) {
      case 'chapters':
        game.reference = chapters.find(byId)
        break
      case 'episodes':
        game.reference = episodes.find(byId)
        break
      default:
        return
    }
    onChange(game)
  }
  handleChange = field => event => {
    const { game, onChange } = this.props
    game[field] = event.target.value
    onChange(game)
  }

  handleChangeUrl = field => value => {
    const { game, onChange } = this.props
    game[field] = value
    onChange(game)
  }

  handleChangeChecked = field => event => {
    const { game, onChange } = this.props
    game[field] = event.target.checked
    onChange(game)
  }

  handleUploadThumbnail = () => {
    const { onSave } = this.props
    onSave()
  }

  render() {
    const { classes, game, episodes, chapters } = this.props
    const {
      _id,
      title = '',
      slug = '',
      state = 'draft',
      thumbnail = '',
      isFree = '',
      content = '',
      reference = '',
      referenceSource = 'episodes',
      category = 'game',
      description = '',
      file = '',
    } = game
    const textProps = { classes, handleChange: this.handleChange }
    const checkProps = { classes, handleChange: this.handleChangeChecked }
    const referenceProps = {
      classes,
      onChange: this.handleChangeReference,
      episodes,
      chapters,
      reference,
      referenceSource,
    }

    return (
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container spacing={24} direction="row" alignItems="flex-start">
          <Grid container item direction="row" xs={12}>
            <Grid item xs={4}>
              <ReferenceField {...referenceProps} />
            </Grid>
            <Grid item xs={4}>
              <ReferenceSourceField {...textProps} value={referenceSource} />
            </Grid>
            <Grid item xs={4}>
              <CategoryField {...textProps} value={category} />
            </Grid>
          </Grid>
          <Grid container direction="column" item xs={12} sm={6}>
            <Grid item xs={12}>
              <TitleField {...textProps} value={title} />
            </Grid>
            <Grid item xs={12}>
              <DescriptionField {...textProps} value={description} />
            </Grid>
            <Grid item xs={12}>
              <FileField {...textProps} value={file} />
            </Grid>
            <Grid container item direction="row">
              <Grid item xs={6}>
                <StateField {...textProps} value={state} />
              </Grid>
              <Grid item xs={6}>
                <IsFreeField {...checkProps} isFree={isFree} />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <CardControl
                url={thumbnail}
                onChangeUrl={this.handleChangeUrl('thumbnail')}
                title="Thumbnail"
                uploadDefaultPrefix="thumbs"
                uploadDefaultName={`th-${slug}`}
                onUpload={this.handleUploadThumbnail}
              />
            </Grid>
          </Grid>
          <Grid container direction="column" item xs={12} sm={6}>
            <Grid item xs={12}>
              <ContentField {...textProps} value={content} />
            </Grid>
            <Grid item xs={12}>
              <GameFrame
                src={Helper.MediaURL(content)}
                token={Helper.token()}
                gameId={_id}
                api={Helper.apiURL}
                frameBorder="0"
                allowFullScreen
                width="100%"
                height="500px"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={8}>
            <Grid item xs={12} sm={6}>
              <SaveButton onClick={this.handleSave} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CancelButton onClick={this.handleCancel} />
            </Grid>
          </Grid>
          {game && game._id && (
            <DangerZone>
              <DeleteGame
                game={game}
                onAfterDelete={this.handleAfterDelete}
                component={DeleteButton}
              />
            </DangerZone>
          )}
        </Grid>
      </form>
    )
  }
}

GameForm.defaultProps = {
  game: {},
}
GameForm.propTypes = {
  classes: PropTypes.object.isRequired,
  game: PropTypes.object,
  episodes: PropTypes.array.isRequired,
  chapters: PropTypes.array.isRequired,
}

export default withChapters(
  withEpisodes(withRouter(withStyles(styles)(GameForm)))
)

const TitleField = memo(({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Title"
      className={classes.textField}
      value={value}
      onChange={handleChange('title')}
      required
    />
  )
})
const DescriptionField = memo(({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Description"
      className={classes.textField}
      value={value}
      onChange={handleChange('description')}
      required
    />
  )
})
const ContentField = memo(({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      ltr
      label="Content"
      className={classes.textField}
      value={value}
      onChange={handleChange('content')}
      required
    />
  )
})

const FileField = memo(({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      ltr
      label="File"
      className={classes.textField}
      value={value}
      onChange={handleChange('file')}
    />
  )
})
const StateField = memo(({ classes, value, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="state-simple">State</InputLabel>
      <Select
        value={value}
        onChange={handleChange('state')}
        inputProps={{
          id: 'state-simple',
        }}
      >
        <MenuItem value="published">Published</MenuItem>
        <MenuItem value="draft">Draft</MenuItem>
      </Select>
    </FormControl>
  )
})

const ReferenceSourceField = memo(({ classes, value, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="refSource-simple">Type</InputLabel>
      <Select
        value={value}
        onChange={handleChange('referenceSource')}
        inputProps={{
          id: 'refSource-simple',
        }}
      >
        <MenuItem value="episodes">episodes</MenuItem>
        <MenuItem value="chapters">chapters</MenuItem>
      </Select>
    </FormControl>
  )
})
const CategoryField = memo(({ classes, value, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="cat-simple">Category</InputLabel>
      <Select
        value={value}
        onChange={handleChange('category')}
        inputProps={{
          id: 'cat-simple',
        }}
      >
        <MenuItem value="game">game</MenuItem>
      </Select>
    </FormControl>
  )
})
const IsFreeField = memo(({ classes, isFree, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel
        control={
          <Switch
            checked={isFree}
            onChange={handleChange('isFree')}
            value="isFree"
          />
        }
        label="Free"
      />
    </FormControl>
  )
})

const ReferenceField = memo(
  ({ classes, onChange, reference, referenceSource, chapters, episodes }) => {
    const props = {
      id: reference ? reference._id : '',
      onChange,
      classes,
    }
    switch (referenceSource) {
      case 'chapters':
        return <ChapterField chapters={chapters} {...props} />
      case 'episodes':
        return <EpisodeField episodes={episodes} {...props} />
      default:
        return null
    }
  }
)
const ChapterField = memo(({ chapters, classes, id, onChange, disabled }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="ch-simple">Chapter</InputLabel>
      <Select
        disabled={disabled}
        value={id}
        onChange={onChange}
        inputProps={{
          id: 'ch-simple',
        }}
      >
        {chapters.map(ch => {
          return (
            <MenuItem key={ch._id} value={ch._id}>
              {ch.title}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
})

const EpisodeField = memo(({ episodes, classes, id, onChange, disabled }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="episode-simple">Episode</InputLabel>
      <Select
        disabled={disabled}
        value={id}
        onChange={onChange}
        inputProps={{
          id: 'episode-simple',
        }}
      >
        {episodes.map(ep => {
          return (
            <MenuItem key={ep._id} value={ep._id}>
              {ep.title}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
})
