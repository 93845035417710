import React from 'react'
import { withRouter } from 'react-router-dom'
import MaterialTable from 'material-table'
import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'
import Helper from '../../utils/Helper'

const pageTitle = 'Maps'
const columns = [
  { title: 'Ref', sort: false, render: r => (r.item ? r.item.title : '') },
  { title: 'Type', field: 'kind' },
  {
    title: 'Published',
    field: 'state',
    type: 'boolean',
    render: rowData => (
      <Checkbox checked={rowData.state === 'published'} disabled />
    ),
  },
]
const options = {
  columnsButton: false,
  exportButton: false,
  filtering: true,
  emptyRowsWhenPaging: false,
  pageSize: 10,
  actionsColumnIndex: -1,
}

// The pagination component is not right with rtl
const components = {
  Pagination: props => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

class Maps extends React.Component {
  state = {
    data: [],
  }
  actions = [
    {
      icon: 'edit',
      tooltip: 'Edit map',
      onClick: (e, row) => this.handleEdit(e, row),
    },
  ]
  async componentDidMount() {
    const data = await Helper.ApiRequest('/maps')
    this.setState({ data })
  }

  handleEdit = (e, rowData) => {
    this.props.history.push(`/maps/${rowData._id}`)
  }

  render() {
    const { data } = this.state

    return (
      <MaterialTable
        columns={columns}
        data={data}
        title={pageTitle}
        options={options}
        components={components}
        actions={this.actions}
      />
    )
  }
}

export default withRouter(Maps)
