import {
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Switch,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { withPaths, withSeries } from '../contexts/data-context'
import Helper from '../utils/Helper'
import { DeleteEpisode } from './Delete'
import { CancelButton, DeleteButton, OutlinedField, SaveButton } from './Forms'
import { DangerZone } from './Grids'
import CardControl from './ImageControl'

const styles = theme => ({
  root: {
    flexGrow: 1,
    // textAlign: 'center',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  listTextField: {
    width: 'calc(100% - 50px)', // We give place to the icon
    marginTop: '0',
    marginBottom: '0',
    paddingTop: '0',
  },
})

class EpisodeForm extends React.Component {
  handleCancel = () => {
    this.props.history.push('/episodes')
  }
  state = {
    series: [],
    filteredSeries: [],
    loading: false,
  }

  componentDidMount() {
    this.fetchSeries()
  }

  handleSave = async e => {
    // We don't want submit to refresh the page
    e.preventDefault()
    const { onSave } = this.props
    return onSave()
  }
  handleAfterDelete = () => {
    const { history } = this.props
    history.push(`/episodes`)
  }
  handleChangeEduTargets = index => event => {
    const { episode, onChange } = this.props
    episode.eduTargets[index] = event.target.value
    onChange(episode)
  }
  handleDeleteEduTargets = index => () => {
    const { episode, onChange } = this.props
    // Remove the element at index
    episode.eduTargets.splice(index, 1)
    onChange(episode)
  }
  handleAddEduTarget = e => {
    const { episode, onChange } = this.props
    const { eduTargets } = episode
    const last = eduTargets[eduTargets.length - 1] || ''
    // Don't add if the last one is already empty
    if (eduTargets.length && last.trim().length === 0) return
    eduTargets.push('')
    episode.eduTargets = eduTargets
    onChange(episode)
  }
  handleChange = field => event => {
    const { episode, onChange } = this.props
    episode[field] = event.target.value
    onChange(episode)
  }
  handleChangeChecked = field => event => {
    const { episode, onChange } = this.props
    episode[field] = event.target.checked
    onChange(episode)
  }
  handleChangeLevel = event => {
                                 const {
                                   episode,
                                   onChange,
                                   paths,
                                 } = this.props
                                 const seriesData = this
                                   .state.series
                                 episode.path = paths.find(
                                   e =>
                                     e._id ===
                                     event.target
                                       .value
                                 )

                                 onChange(
                                   episode
                                 )

                                 const filteredSeries = seriesData.filter(
                                   oneSeries =>
                                     oneSeries
                                       .path
                                       ._id ===
                                     episode.path
                                       ._id
                                 )

                                 this.setState({
                                   filteredSeries,
                                 })
                               }

  handleChangeSeries = event => {
    const { episode, onChange } = this.props
    const seriesData = this.state.series

    episode.series = seriesData.find(e => {
      return e._id === event.target.value
    })
    onChange(episode)
  }

  handleChangeUrl = field => value => {
    const { episode, onChange } = this.props
    episode[field] = value
    onChange(episode)
  }

  handleUploadThumbnail = () => {
    const { onSave } = this.props
    onSave()
  }

  fetchSeries = async () => {
    const { episode } = this.props

    this.setState({ loading: true })
    const series = await Helper.ApiRequest('/series')
    this.setState({ series })

    const filteredSeries = series.filter(
      oneSeries => episode.path && oneSeries.path._id === episode.path._id
    )

    this.setState({ filteredSeries, loading: false })
  }

  render() {
    const { classes, paths, episode } = this.props
    const seriesData = this.state.filteredSeries

    const {
      title = '',
      slug = '',
      order = '',
      path = '',
      description = '',
      state = 'draft',
      eduTargets = [],
      thumbnail = '',
      image = '',
      isFree = false,
      series = '',
    } = episode
    const textProps = {
      classes,
      handleChange: this.handleChange,
    }
    const checkProps = {
      classes,
      handleChange: this.handleChangeChecked,
    }
    const pathProps = {
      classes,
      onChange: this.handleChangeLevel,
      paths,
      id: (path && path._id) || '',
    }
    const seriesProps = {
      classes,
      onChange: this.handleChangeSeries,
      seriesData,
      id: (series && series._id) || '',
    }

    return (
      <form className={classes.root} noValidate autoComplete="off">
        {!this.state.loading ? (
          <Grid container spacing={24} alignItems="flex-start">
            <Grid item xs={12} sm={6} md={2}>
              <TitleField {...textProps} value={title} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <SlugField {...textProps} value={slug} />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <OrderField {...textProps} value={order} />
            </Grid>

            <Grid item xs={12} sm={3} md={2}>
              <LevelField {...pathProps} />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StateField {...textProps} value={state} />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <IsFreeField {...checkProps} isFree={isFree} />
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <SeriesField {...seriesProps} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container direction="column">
                <DescriptionField {...textProps} value={description} />
                <EduTargetsField
                  classes={classes}
                  eduTargets={eduTargets}
                  onAdd={this.handleAddEduTarget}
                  handleChange={this.handleChangeEduTargets}
                  handleDelete={this.handleDeleteEduTargets}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <CardControl
                url={thumbnail}
                title="Thumbnail"
                onUpload={this.handleUploadThumbnail}
                onChangeUrl={this.handleChangeUrl('thumbnail')}
                uploadDefaultPrefix="thumbs"
                uploadDefaultName={`th-${slug}`}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <CardControl
                url={image}
                title="Background"
                onUpload={this.handleUploadThumbnail}
                onChangeUrl={this.handleChangeUrl('image')}
                uploadDefaultPrefix="bgs"
                uploadDefaultName={`bg-${slug}`}
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3} md={2}>
                <SaveButton onClick={this.handleSave} />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <CancelButton onClick={this.handleCancel} />
              </Grid>
            </Grid>
            {episode._id && (
              <DangerZone>
                <DeleteEpisode
                  episode={episode}
                  onAfterDelete={this.handleAfterDelete}
                  component={DeleteButton}
                />
              </DangerZone>
            )}
          </Grid>
        ) : (
          <CircularProgress className={classes.progress} />
        )}
      </form>
    )
  }
}

EpisodeForm.defaultProps = {}
EpisodeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  episode: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default withSeries(
  withPaths(withSnackbar(withRouter(withStyles(styles)(EpisodeForm))))
)

const TitleField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Title"
      className={classes.textField}
      value={value}
      onChange={handleChange('title')}
      required
    />
  )
}

const SlugField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Slug"
      className={classes.textField}
      value={value}
      onChange={handleChange('slug')}
      required
    />
  )
}

const OrderField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Order"
      className={classes.textField}
      value={value}
      onChange={handleChange('order')}
      required
    />
  )
}

const DescriptionField = ({ classes, handleChange, value }) => {
  return (
    <OutlinedField
      label="Description"
      className={classes.textField}
      value={value}
      onChange={handleChange('description')}
      multiline
      // rowsMax="4"
      rows="4"
    />
  )
}

const LevelField = ({ classes, id, paths, onChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="level-simple">Level</InputLabel>
      <Select
        value={id}
        onChange={onChange}
        inputProps={{
          id: 'level-simple',
        }}
      >
        {paths.map(p => {
          return (
            <MenuItem key={p._id} value={p._id}>
              {p.title}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const SeriesField = ({ classes, id, seriesData, onChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="series-simple">Series</InputLabel>
      <Select
        value={id}
        onChange={onChange}
        inputProps={{
          id: 'series-simple',
        }}
      >
        {Array.isArray(seriesData) &&
          seriesData.map(oneSeries => {
            return (
              <MenuItem key={oneSeries._id} value={oneSeries._id}>
                {`${oneSeries.title} (${oneSeries.path.title})`}
              </MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
}

const StateField = ({ classes, value, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="state-simple">State</InputLabel>
      <Select
        value={value}
        onChange={handleChange('state')}
        inputProps={{
          id: 'state-simple',
        }}
      >
        <MenuItem value="published">Published</MenuItem>
        <MenuItem value="draft">Draft</MenuItem>
      </Select>
    </FormControl>
  )
}
const IsFreeField = ({ classes, isFree, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel
        control={
          <Switch
            checked={isFree}
            onChange={handleChange('isFree')}
            value="isFree"
          />
        }
        label="Free"
      />
    </FormControl>
  )
}
const EduTargetsField = ({
  classes,
  eduTargets,
  handleChange,
  handleDelete,
  onAdd,
}) => {
  return (
    <List subheader={<EduTargetsHeader onClick={onAdd} />}>
      {eduTargets.map((eduTarget, index) => {
        return (
          <ListItem dense key={index}>
            <ListItemText>
              <TextField
                margin="dense"
                variant="filled"
                className={classes.listTextField}
                value={eduTarget}
                onChange={handleChange(index)}
              />
              <IconButton aria-label="Delete" onClick={handleDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemText>
          </ListItem>
        )
      })}
    </List>
  )
}

const EduTargetsHeader = ({ onClick }) => {
  return (
    <ListSubheader>
      Educational Targets
      <IconButton onClick={onClick}>
        <AddIcon />
      </IconButton>
    </ListSubheader>
  )
}
