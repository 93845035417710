import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LinkIcon from '@material-ui/icons/Link'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import ChapterForm from '../../components/chapter/ChapterForm'
import { withEpisodes } from '../../contexts/data-context'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { ChapterDetailStyle as styles } from './ChapterStyles'

const ChapterDetail = props => {
  const { classes, enqueueSnackbar, match } = props
  const [chapter, setChapter] = useState({})
  const history = useHistory()

  const fetchData = async () => {
    // race condition problem for slug
    let { slug } = match.params
    if (chapter && chapter.slug) slug = chapter.slug
    const [data] = await Helper.ApiRequest(`/chapters?slug=${slug}`)
    setChapter(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const saveChapter = async () => {
    const [promise, onSuccess] = await getSaveRequest(chapter)
    doAndSnack(promise, enqueueSnackbar, {
      success: `Chapter successfully saved`,
      fail: `Failed to save chapter`,
      onSuccess,
    })
  }

  const getSaveRequest = async chapter => {
    const { _id, slug } = chapter
    const path = `/chapters/${_id}`
    const method = 'PUT'
    const body = JSON.stringify(chapter)
    const promise = await Helper.ApiRequest(path, {
      method,
      body,
    })

    const onSuccess = () => {
      history.push(`/chapters/${slug}`)
      fetchData()
    }
    return [promise, onSuccess]
  }

  const handleChange = data => {
    setChapter({
      ...data,
    })
  }

  return (
    <>
      <FormTitle episode={chapter.episode} />
      <Paper className={classes.paper}>
        <ChapterForm
          chapter={chapter}
          onChange={handleChange}
          onSave={saveChapter}
        />
      </Paper>
    </>
  )
}


ChapterDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(
  withEpisodes(withRouter(withStyles(styles)(ChapterDetail)))
)

function FormTitle({ episode }) {
  const { title, slug } = episode || {}

  return (
    <Typography component="h2" variant="h4" align="center">
      Episode Title : {title}
      <Link to={`/episodes/${slug}/chapters`}>
        <LinkIcon />
      </Link>
    </Typography>
  )
}
