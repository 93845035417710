import { Paper, Typography, withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { BookAddStyle as styles } from './BookStyle'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack'
import BookForm from '../../components/book/BookForm'

const BookAdd = props => {
  const { classes, enqueueSnackbar } = props
  const { search } = useLocation()
  const history = useHistory()

  const [book, setBook] = useState({
    category: 'book',
    referenceSource: 'episodes',
  })

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const searchParams = new URLSearchParams(search)
    const episodeParam = searchParams.get('episode')
    const [episode] = episodeParam
      ? await Helper.ApiRequest(`/episodes?slug=${episodeParam}`)
      : []
    book.reference = episode
    setBook(book)
  }

  const saveBook = () => {
    const [promise, onSuccess] = getSaveRequest(book)
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Book succesfully saved',
      fail: 'Failed to save book',
      onSuccess,
    })
  }

  const onSuccess = () => {
    const epslug = book.reference.slug
    history.push(`/episodes/${epslug}/books`)
  }

  const getSaveRequest = data => {
    const path = '/activities'
    const method = 'POST'
    const body = JSON.stringify(data)
    const promise = Helper.ApiRequest(path, { method, body })

    return [promise, onSuccess]
  }

  const handleChange = book => {
    setBook(book)
  }

  return (
    <>
      <FormTitle />
      <Paper className={classes.paper}>
        <BookForm onChange={handleChange} book={book} onSave={saveBook} />
      </Paper>
    </>
  )
}

BookAdd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withRouter(withStyles(styles)(BookAdd)))

const FormTitle = () => {
  return (
    <Typography component="a" variant="h4" align="center">
      Add a book
    </Typography>
  )
}
