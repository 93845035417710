import React, { useEffect, useState } from 'react'
import {
  Paper,
  Typography,
  withStyles,
  TextField,
  Button,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { SettingsStyle as styles } from './style'
import Helper from '../../utils/Helper'
import { withSnackbar } from 'notistack'
import { doAndSnack, snackProgressBar } from '../../utils/snackbar'

const Settings = props => {
  const { enqueueSnackbar, classes } = props
  const [settings, setSettings] = useState({})
  const [canAdd, setCanAdd] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const type = 'parent-course-url'
      const data = await Helper.ApiRequest(`/settings/type/${type}`)
      setSettings(data)
    } catch (error) {
      const { message } = error
      if (message === 'المحتوى المطلوب غير متوفر') {
        setCanAdd(true)
      }
    }
  }

  const doUpdateData = (e, setting) => {
    e.preventDefault()
    let isValidURL = Helper.validateURL(setting.url)

    if (!isValidURL) {
      snackProgressBar(
        enqueueSnackbar,
        {
          success: 'URL is valid',
          fail: 'Must be a valid URL',
        },
        isValidURL
      )
      return
    }

    const body = JSON.stringify(settings)
    const path = `/settings/setting/${settings.id}`
    const method = 'PUT'
    const promise = Helper.ApiRequest(path, {
      method,
      body,
    })

    doAndSnack(promise, enqueueSnackbar, {
      success: 'Succesfully update the parent course url',
      fail: 'Failed to update the parent course url',
    })
  }

  const doAddData = (e, setting) => {
    e.preventDefault()
    let isValidURL = Helper.validateURL(setting.url)

    if (!isValidURL) {
      snackProgressBar(
        enqueueSnackbar,
        {
          success: 'URL is valid',
          fail: 'Must be a valid URL',
        },
        isValidURL
      )
      return
    }

    const type = 'parent-course-url'
    const body = JSON.stringify({
      url: settings.url,
    })
    const path = `/settings/type/${type}`
    const method = 'POST'

    const promise = Helper.ApiRequest(path, {
      method,
      body,
    })

    const onSuccess = res => {
      setSettings(res)
      setCanAdd(false)
    }

    doAndSnack(promise, enqueueSnackbar, {
      success: 'Succesfully add the parent course url',
      fail: 'Failed to add the parent course url',
      onSuccess,
    })
  }

  return (
    <>
      <Typography component="h1" variant="h5" align="center">
        Parent Course Control
      </Typography>
      <Paper className={classes.paper}>
        <div className={classes.container}>
          <TextField
            id="outlined-basic"
            label="Parent Course URL"
            variant="outlined"
            type="text"
            defaultValue={settings.url}
            value={settings.url}
            fullWidth
            onChange={e => {
              setSettings({
                ...settings,
                url: e.target.value,
              })
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {canAdd ? (
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              color="primary"
              onClick={e => {
                doAddData(e, settings)
              }}
            >
              Add
            </Button>
          ) : (
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              color="primary"
              onClick={e => {
                doUpdateData(e, settings)
              }}
            >
              Edit
            </Button>
          )}
        </div>
      </Paper>
    </>
  )
}

export default withSnackbar(withRouter(withStyles(styles)(Settings)))
