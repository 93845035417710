import { Button, Grid, Typography } from '@material-ui/core'
import UploadIcon from '@material-ui/icons/CloudUpload'
import React, { memo } from 'react'
const UploadField = memo(({ classes, onChange, editMode }) => {
  return (
    <Grid item container sm={8} md={6}>
      <Grid item xs={12} sm={6} container alignItems="center">
        <Typography>Upload a file (Photo or Video)</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <input
          accept="image/*,video/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={onChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            component="span"
            className={classes.button}
            color="primary"
          >
            <UploadIcon className={classes.buttonIcon} />
            {editMode ? 'Update' : 'Upload'}
          </Button>
        </label>
      </Grid>
    </Grid>
  )
})

export default UploadField
