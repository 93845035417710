import React, { Component } from 'react'
import Config from '../config'
const api = Config.apiURL
export default class GameFrame extends Component {
  handleLoad = e => {
    const { gameId, token } = this.props
    const iframeWindow = e.target.contentWindow
    iframeWindow.postMessage({ gameId, token, api }, '*')
  }
  render() {
    var props = { ...this.props }
    delete props.token
    delete props.gameId
    return <iframe title="game-iframe" onLoad={this.handleLoad} {...props} />
  }
}
