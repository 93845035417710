import React from 'react'
import PropTypes from 'prop-types'

import './map-viewer.css'

/**
 * This component is the main entry to view a map.
 *
 * We display locations as svg and not img because
 * we want to control there width and height.
 * With svg tag we can override width and height
 * if the are already set in the svg file.
 * @param {*} param0
 */
const MapViewer = ({ bg, locations, iconActive, iconDone, viewedLocations }) => {
  // The active location is the first one that has not yet
  // been watched
  const activeIndex = locations.findIndex(
    l => viewedLocations.indexOf(l.item) < 0
  )

  return (
    <div id="map-container">
      <svg viewBox="0 0 1340 667">
        <image width="1340" height="667" href={bg} />
        {locations.map((l, i) => (
          <Location
            key={i}
            location={l}
            iconActive={iconActive}
            iconDone={iconDone}
            activeIndex={activeIndex}
            index={i}
          />
        ))}
      </svg>
    </div>
  )
}

MapViewer.defaultProps = {
  locations: [],
  bg: '',
  viewedLocations: [],
}

MapViewer.propTypes = {
  locations: PropTypes.array,
  bg: PropTypes.string,
  viewedChapters: PropTypes.array,
}
export default MapViewer

const Location = ({ location, iconActive, iconDone, activeIndex, index }) => {
  const {
    normal,
    disabled = normal,
    hover = normal,
    height = 0,
    width = 0,
    x = 0,
    y = 0,
  } = location
  const imgProps = { width, height }
  const iconProps = { width: '5', height: '5' }
  const actProps = {
    x: (width * 5) / 6,
    y: height / 6,
    ...iconProps,
  }
  const doneProps = {
    x: width / 2,
    y: height / 3,
    ...iconProps,
  }
  const allDone = activeIndex < 0
  const isActive = activeIndex === index
  const isDone = activeIndex > index || allDone
  const enabled = index <= activeIndex || allDone
  // The location is in a viewbox divided by 100 so the user can
  // give percentages for width and height
  return (
    <svg
      viewBox="0 0 134 66.7"
      className="loc-container"
      key={normal + index}
      x={x * 13.4}
      y={y * 6.67}
    >
      {enabled && <image href={hover} className="loc hover" {...imgProps} />}
      {enabled && <image href={normal} className="loc normal" {...imgProps} />}
      {!enabled && (
        <image href={disabled} className="loc disabled" {...imgProps} />
      )}
      {isActive && (
        <image href={iconActive} className="icon-active bounce" {...actProps} />
      )}
      {isDone && <image href={iconDone} className="icon-done" {...doneProps} />}
    </svg>
  )
}
