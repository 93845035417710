import React from 'react'
import Helper from '../utils/Helper'

export const LoginContext = React.createContext({
  isAuth: false,
  setAuth: () => {},
})

export class LoginProvider extends React.Component {
  setAuth = isAuth => {
    this.setState({ isAuth })
  }
  state = {
    isAuth: Helper.isAuthenticated(),
    setAuth: this.setAuth,
  }
  render() {
    const { isAuth, setAuth } = this.state
    const { children } = this.props
    const context = { isAuth, setAuth }
    return (
      <LoginContext.Provider value={context}>{children}</LoginContext.Provider>
    )
  }
}
export const withLoginContext = Element =>
  React.forwardRef((props, ref) => (
    <LoginContext.Consumer>
      {context => (
        <Element
          isAuth={context.isAuth}
          setAuth={context.setAuth}
          {...props}
          ref={ref}
        />
      )}
    </LoginContext.Consumer>
  ))
