import { Button, CircularProgress, Grid } from '@material-ui/core'
import React, { memo } from 'react'

const ButtonSection = memo(
  ({ classes, onSave, loading, onCancel, editMode }) => {
    return (
      <Grid item container sm={8} md={4} justify="center" alignItems="center">
        {!loading ? (
          <>
            <Grid item xs={12} sm={6} container justify="center">
              <Button
                variant="contained"
                component="span"
                className={classes.button}
                color="secondary"
                onClick={onSave}
              >
                {editMode ? 'Update' : 'Create Page'}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} container justify="center">
              <Button
                variant="contained"
                component="span"
                className={classes.button}
                color="default"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Grid>
          </>
        ) : (
          <CircularProgress className={classes.progress} />
        )}
      </Grid>
    )
  }
)

export default ButtonSection
