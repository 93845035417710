import React from 'react'
import Helper from '../utils/Helper'
import { withLoginContext } from './login-context'

// Generic data provider
export const dataProvider = (Context, path) =>
  withLoginContext(
    class extends React.Component {
      state = {
        data: [],
      }
      componentDidMount() {
        this.fetchData()
      }
      componentDidUpdate(prevProps) {
        if (prevProps.isAuth !== this.props.isAuth) {
          this.fetchData()
        }
      }
      fetchData = async () => {
        if (!this.props.isAuth) return
        const data = await Helper.ApiRequest(path)
        this.setState({ data })
      }
      render() {
        const { data } = this.state
        const { children } = this.props
        return <Context.Provider value={data}>{children}</Context.Provider>
      }
    }
  )

export const PathsContext = React.createContext({ data: [] })
export const SeriesContext = React.createContext({ data: [] })
export const EpisodesContext = React.createContext({ data: [] })
export const ChaptersContext = React.createContext({ data: [] })
export const PathsProvider = dataProvider(PathsContext, '/paths')
export const SeriesProvider = dataProvider(SeriesContext, '/series')
export const EpisodesProvider = dataProvider(EpisodesContext, '/episodes')
export const ChaptersProvider = dataProvider(ChaptersContext, '/chapters')

// These HOC are helpers to inject data in props
export const withPaths = Element =>
  React.forwardRef((props, ref) => (
    <PathsContext.Consumer>
      {context => <Element paths={context} {...props} ref={ref} />}
    </PathsContext.Consumer>
  ))
export const withEpisodes = Element =>
  React.forwardRef((props, ref) => (
    <EpisodesContext.Consumer>
      {context => <Element episodes={context} {...props} ref={ref} />}
    </EpisodesContext.Consumer>
  ))

export const withChapters = Element =>
  React.forwardRef((props, ref) => (
    <ChaptersContext.Consumer>
      {context => <Element chapters={context} {...props} ref={ref} />}
    </ChaptersContext.Consumer>
  ))

export const withSeries = Element =>
  React.forwardRef((props, ref) => (
    <SeriesContext.Consumer>
      {context => <Element seriesContext={context} {...props} ref={ref} />}
    </SeriesContext.Consumer>
  ))
