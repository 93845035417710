import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  LinearProgress,
  withStyles,
} from '@material-ui/core'
import CloudUpload from '@material-ui/icons/CloudUpload'
import DoneIcon from '@material-ui/icons/Done'
import WarningIcon from '@material-ui/icons/Warning'
import memoize from 'memoize-one'
import { withSnackbar } from 'notistack'
import React, { Component } from 'react'
import Helper from '../utils/Helper'
import { getExtension, toBlob, readableSize } from '../utils/image'
import { assetServer, getPath, isInServer } from '../utils/url'
import { LtrTextField } from './Forms'

const styles = theme => ({
  card: {
    maxWidth: 345,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  cardContent: {
    padding: theme.spacing.unit,
  },
  cardActions: {
    padding: 0,
  },
  cardInput: {
    display: 'none',
  },
  cardField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  chip: {
    margin: theme.spacing.unit,
  },
})

class CardControl extends Component {
  state = {
    imageExist: false,
    loading: false,
    src: null,
    open: false,
    upload: null,
    uploadName: '',
    hash: Date.now(),
    width: 0,
    height: 0,
  }
  // Re-run the filter whenever the list array or filter text changes:
  inServer = memoize(url => isInServer(url))
  getPath = memoize(url => getPath(url))
  getStampedUrl = memoize((url, hash) => {
    const mediaUrl = Helper.MediaURL(url)
    return mediaUrl.indexOf('?') > 0
      ? `${mediaUrl}&${hash}`
      : `${mediaUrl}?${hash}`
  })

  startLoading = () => {
    this.setState({ loading: true })
  }
  stopLoading = () => {
    this.setState({ loading: false })
  }
  handleLoadImage = async (e, a) => {
    const {
      url,
      uploadDefaultPrefix: prefix,
      uploadDefaultName: name,
    } = this.props
    const { src, naturalWidth: width, naturalHeight: height } = e.target
    this.setState({ src, width, height })

    toBlob(src).then(upload => {
      this.setState({ size: upload.size })
      // If the image is not in the server we save it to
      // let the user upload it directly in the server
      if (!this.inServer(url)) {
        const uploadName = `${prefix}/${name}.${getExtension(upload)}`
        this.setState({ upload, uploadName })
        this.setImageExist(assetServer + uploadName)
      }
    })
  }
  clearUpload = () => {
    this.setState({ upload: null, uploadName: '' })
  }
  handleUpload = e => {
    const { uploadDefaultPrefix } = this.props
    const upload = e.target.files[0]
    const uploadName = uploadDefaultPrefix + '/' + upload.name
    this.setState({ upload, uploadName })
    this.setImageExist(assetServer + uploadName)
  }
  handleUploadChange = e => {
    const uploadName = e.target.value
    this.setState({ uploadName })
    this.setImageExist(assetServer + uploadName)
  }
  setImageExist(url) {
    fetch(url).then(res => {
      const imageExist = res.ok
      this.setState({ imageExist })
    })
  }
  handleChangeUrl = e => {
    const { onChangeUrl, url } = this.props
    const { value } = e.target
    const fullValue = this.inServer(url) ? assetServer + value : value
    onChangeUrl(fullValue)
  }

  newHash = () => {
    this.setState({ hash: Date.now() })
  }
  putInServer = async () => {
    const { enqueueSnackbar: snack, onChangeUrl, onUpload } = this.props
    const { uploadName, upload, loading } = this.state
    if (loading) return
    this.startLoading()

    // Send image to server
    const formData = new FormData()
    formData.append('upload', upload)
    Helper.uploadAsset(uploadName, formData)
      .then(() => {
        this.stopLoading()
        this.clearUpload()
        this.newHash()
        onChangeUrl(assetServer + uploadName)
        snack('Upload successful', {
          variant: 'success',
        })
        if (onUpload) onUpload()
      })
      .catch(err => {
        console.error(err)
        this.stopLoading()
        this.clearUpload()
        snack('Error upload', {
          variant: 'error',
        })
      })
  }
  handleChangeNewPath = e => {
    // we remove first slashes
    const newPath = e.target.value.replace(/^\/*/, '')
    this.setState({ newPath })
  }
  render() {
    const {
      imageExist,
      loading,
      upload,
      uploadName,
      hash,
      height,
      width,
      size,
    } = this.state
    const { classes, url, title } = this.props
    const inServer = this.inServer(url)
    const path = this.getPath(url)
    const src = this.getStampedUrl(url, hash)

    return (
      <>
        <Card className={classes.card}>
          <CardMedia
            component="img"
            alt={title}
            className={classes.media}
            image={src}
            title={title}
            onLoad={this.handleLoadImage}
          />

          <CardContent className={classes.cardContent}>
            {loading && <LinearProgress />}
            {path && <Chips width={width} height={height} size={size} />}
            <PathField
              inServer={inServer}
              path={path}
              onChange={this.handleChangeUrl}
              label={title}
            />
            <UploadField
              upload={upload}
              imageExist={imageExist}
              uploadName={uploadName}
              onChange={this.handleUploadChange}
            />
          </CardContent>
          <CardActions className={classes.cardActions}>
            {upload ? (
              <SimpleButton
                onClick={this.putInServer}
                text="Upload to server"
              />
            ) : (
              <UploadButton onUpload={this.handleUpload} />
            )}
          </CardActions>
        </Card>
      </>
    )
  }
}

export default withSnackbar(withStyles(styles)(CardControl))

const Chips = withStyles(styles)(({ classes, width, height, size }) => {
  const widthLabel = `${width}x${height}`
  const sizeLabel = readableSize(size)
  return (
    <>
      <Chip className={classes.chip} label={widthLabel} />
      <Chip className={classes.chip} label={sizeLabel} />
    </>
  )
})
const SimpleButton = ({ text, ...props }) => {
  return (
    <Button size="small" color="primary" {...props}>
      {text}
    </Button>
  )
}
const UploadButton = withStyles(styles)(({ classes, onUpload }) => {
  return (
    <>
      <input
        accept="image/*"
        className={classes.cardInput}
        id="contained-button-file"
        type="file"
        onChange={onUpload}
      />
      <label htmlFor="contained-button-file">
        <Button
          size="small"
          color="primary"
          component="span"
          className={classes.button}
        >
          Upload
        </Button>
      </label>
    </>
  )
})

const PathField = withStyles(styles)(
  ({ classes, path, inServer, ...others }) => {
    if (!path) return null
    const fieldProps = {
      helperText: inServer ? assetServer : '',
      value: path,
      className: classes.cardField,
    }
    const chipServerProps = {
      label: inServer ? `In server` : 'Not in server',
      color: inServer ? 'secondary' : 'default',
      icon: inServer ? <DoneIcon /> : <CloudUpload />,
      className: classes.chip,
    }
    return (
      <>
        <Chip {...chipServerProps} />
        <LtrTextField {...fieldProps} {...others} />
      </>
    )
  }
)

const UploadField = withStyles(styles)(
  ({ classes, upload, uploadName, imageExist, onChange }) => {
    if (!upload) return null
    const fieldProps = {
      value: uploadName,
      className: classes.cardField,
      label: 'New file path',
      onChange,
    }
    const chipExistProps = {
      label: imageExist ? `Exists` : "Don't exist",
      color: imageExist ? 'default' : 'secondary',
      icon: imageExist ? <WarningIcon /> : <DoneIcon />,
      className: classes.chip,
    }
    return (
      <>
        <LtrTextField {...fieldProps} />
        <Chip {...chipExistProps} />
      </>
    )
  }
)
