import Config from '../config'
var AES = require('crypto-js/aes')
var encUtf8 = require('crypto-js/enc-utf8')

// @TODO: filter what we need in this file from what we don't
const Helper = {
  ApiRequest: function(
    url,
    options,
    auth = true,
    overideOptions = false,
    token = false
  ) {
    //TODO test if real url
    url = Config.apiURL + url

    if (!options) options = {}
    if (!options.headers) options.headers = {}

    options.headers['Screen-Resolution'] =
      window.screen.width * window.devicePixelRatio +
      ' X ' +
      window.screen.height * window.devicePixelRatio
    options.headers['Application-Id'] = 'dashboard'

    if (!overideOptions) {
      if (!options.headers) options.headers = {}

      if (!options.headers['Accept'])
        options.headers['Accept'] = 'application/json'

      if (!options.headers['Content-Type'])
        options.headers['Content-Type'] = 'application/json'
    }

    if (auth === true) {
      if (!options.headers) options.headers = {}
      options.headers['Authorization'] = !token
        ? Helper.token()
        : 'Bearer ' + token
    }

    var promise = new Promise(function(resolve, reject) {
      fetch(url, options)
        .then(function(response) {
          if (response.status === 401) {
            // 401 Unauthorized access
            Helper.logOut()
            window.location = '/login'
            return
          }
          if (response.status === 204) {
            return resolve(true)
          }
          if (response.status === 404) {
            return reject(new Error('المحتوى المطلوب غير متوفر'))
          }
          if (response.status === 500) {
            return reject(new Error('المعذرة. لقد حدث عطل بالخادم'))
          }
          return response.json().then(function(data) {
            if (response.status === 400) {
              resolve({ error: true, message: data })
            }
            resolve(data)
          })
        })
        .catch(function(err) {
          reject(err)
        })
    })
    return promise
  },
  isAuthenticated: function() {
    return Helper.getLocalStorageItem(Config.localStorage.tokenIndex) !== null
  },
  token: function() {
    return (
      'Bearer ' + Helper.getLocalStorageItem(Config.localStorage.tokenIndex)
    )
  },
  logOut: function() {
    localStorage.removeItem(Config.localStorage.keyName)
  },
  log: function() {
    if (Config.enableLog) console.log.apply(this, arguments)
  },
  whoami: function(forcenew = false) {
    var promise = new Promise((resolve, reject) => {
      var me = Helper.getLocalStorageItem(Config.localStorage.whoamiIndex)
      let exists = me != null

      if (!forcenew && exists) return resolve(JSON.parse(me))

      let url = '/me'
      Helper.ApiRequest(url)
        .then(profile => {
          Helper.setLocalStorageItem(
            Config.localStorage.whoamiIndex,
            JSON.stringify(profile)
          )
          resolve(profile)
        })
        .catch(err => {
          reject(err)
        })
    })
    return promise
  },
  date: function(mongooseDate) {
    let date = new Date(mongooseDate)
    return date.toLocaleString()
  },
  setEncryptionKey: function(token) {
    var promise = new Promise((resolve, reject) => {
      var key = Helper.getLocalStorageItem(Config.localStorage.keyName)
      if (key) {
        resolve(key)
        return
      } else {
        let url = '/key'
        Helper.ApiRequest(url, null, true, false, token)
          .then(key => {
            Helper.setLocalStorageItem(Config.localStorage.keyName, key)
            resolve(key)
          })
          .catch(err => {
            reject(err)
          })
      }
    })
    return promise
  },
  getLocalStorageItem: function(key) {
    var storage = localStorage.getItem(Config.localStorage.keyName)
    if (storage == null) return null

    var encryptionKey = storage.substr(
      storage.length - Config.localStorage.keyLength
    )

    if (key === Config.localStorage.keyName) {
      return encryptionKey
    }

    storage = storage.slice(0, -Config.localStorage.keyLength)

    // if after slicing, the storage is empty
    if (storage === '') {
      return null
    } else {
      var bytes = AES.decrypt(storage, encryptionKey)
      var obj = JSON.parse(bytes.toString(encUtf8))
      // if key is not specified, return the whole storage as object
      if (!key) {
        return obj
      } else {
        return obj[key]
      }
    }
  },
  setLocalStorageItem: function(key, value) {
    var storage = localStorage.getItem(Config.localStorage.keyName)

    //if the key to be added is the encryption key appending the encryption key to localstorage without encrypting it
    if (key === Config.localStorage.keyName && storage == null) {
      localStorage.setItem(key, value)
      return value
    } else {
      // if a different key is to be set || the storage is containing something else

      // if there is already stored data, it means the key is in the end of the string
      var encryptionKey = storage.substr(
        storage.length - Config.localStorage.keyLength
      )
      var encryptedValue, obj

      storage = storage.slice(0, -Config.localStorage.keyLength)

      if (storage === '') {
        // at this point, we leant the encryption key is in the localstorage (localstorage containing encryption key only), we set the new value
        if (key === Config.localStorage.keyName)
          return localStorage.setItem(key, value)

        obj = {}
        obj[key] = value
        encryptedValue = AES.encrypt(JSON.stringify(obj), encryptionKey)
        // we append the encryption key
        encryptedValue += encryptionKey
        localStorage.setItem(Config.localStorage.keyName, encryptedValue)
        return true
      } else {
        obj = Helper.getLocalStorageItem()
        delete obj[key]
        obj[key] = value
        // var obj = {key:value};
        encryptedValue = AES.encrypt(JSON.stringify(obj), encryptionKey)
        // we append the encryption key again
        encryptedValue += encryptionKey
        localStorage.setItem(Config.localStorage.keyName, encryptedValue)
        return true
      }
    }
  },
  MediaURL: function(resource, medium = 'file') {
    var isDbIdRegex = new RegExp('^[0-9a-fA-F]{24}$')
    if (!isDbIdRegex.test(resource)) return resource // not a database ID

    var access_token = Helper.getLocalStorageItem(
      Config.localStorage.tokenIndex
    )
    if (access_token) access_token = '?access_token=' + access_token

    var url = Config.apiURL + '/media/' + medium + '/' + resource + access_token
    Helper.log('MediaURL Generated\n', url)
    return url
  },
  uploadAsset: (path, formData) => {
    return Helper.ApiRequest(
      `/assets/upload?filename=${path}`,
      {
        method: 'POST',
        body: formData,
      },
      true,
      true // don't override content-type for multipart data
    )
  },
  uploadVideoToHLS: formData => {
    return Helper.ApiRequest(
      `/assets/video/upload/mp4`,
      {
        method: 'POST',
        body: formData,
      },
      true,
      true // don't override content-type for multipart data
    )
  },
  validateURL: url => {
    try {
      new URL(url)
      return true
    } catch (error) {
      return false
    }
  },
  isJsonString: data => {
    try {
      if (typeof data !== 'object') return false

      JSON.parse(JSON.stringify(data))
    } catch (e) {
      console.log(e)
      return false
    }
    return true
  },
}

export default Helper
