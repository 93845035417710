const commonStyle = theme => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
})

export const SettingsStyle = theme => ({
  ...commonStyle(theme),
  container: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
})

export const FormStyle = theme => ({
  ...commonStyle(theme),
  container: {
    paddingBottom: theme.spacing.unit * 10,
    margin: theme.spacing.unit,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  timeField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,

    width: 200,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginBotton: theme.spacing.unit * 10,
    marginRight: theme.spacing.unit,
    width: 400,
  },
})
