import React, { useEffect, useState } from 'react'
import {
  Button,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useHistory, withRouter } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import Helper from '../../utils/Helper'
import { CategoriesStyle as styles } from './CategoriesStyle'

const Categories = props => {
  const { classes } = props
  const [categories, setCategories] = useState([])
  const history = useHistory()

  const fetchData = async () => {
    const data = await Helper.ApiRequest(`/categories`)
    setCategories(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleChange = id => event => {
    const category = categories.find(category => category._id === id)
    category.isPartition = event.target.checked
    setCategories([...categories])
    updateCategory(id, category)
  }

  const updateCategory = async (id, category) => {
    const body = JSON.stringify(category)
    const method = 'PUT'
    await Helper.ApiRequest(`/categories/${id}`, { method, body })
    fetchData()
  }

  const handleDelete = id => async () => {
    const method = 'DELETE'
    await Helper.ApiRequest(`/categories/${id}`, { method })
    fetchData()
  }

  const handleAdd = () => {
    history.push('/categories/add')
  }

  const handleEdit = id => () => {
    history.push(`/categories/edit/${id}`)
  }

  return (
    <>
      <Header onAdd={handleAdd} />
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableHeader}>
                Category
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                Parent Category
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                Set as a Partition
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories && categories.length > 0 ? (
              categories.map(category => (
                <TableRow key={category._id}>
                  <TableCell align="center" className={classes.tableCell}>
                    {category.title}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {category.parentCategory && category.parentCategory.title}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Switch
                      checked={category.isPartition}
                      onChange={handleChange(category._id)}
                      color="secondary"
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Button
                      size="small"
                      variant="contained"
                      component="span"
                      color="secondary"
                      onClick={handleEdit(category._id)}
                    >
                      <EditIcon />
                    </Button>
                    {category.childCategories &&
                      category.childCategories.length <= 0 && (
                        <Button
                          style={{ marginRight: 10 }}
                          size="small"
                          variant="contained"
                          component="span"
                          onClick={handleDelete(category._id)}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'center' }}
                  colSpan="4"
                >
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default withRouter(withStyles(styles)(Categories))

const Header = props => {
  const { onAdd } = props
  return (
    <>
      <Grid
        container
        direction="row-reverse"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            MANAGE CATEGORY LIST
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={8} direction="row-reverse">
            <Grid item>
              <Button
                size="small"
                variant="contained"
                component="span"
                color="primary"
              >
                Manage Content
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                component="span"
                color="primary"
              >
                Create Tag Category
                <AddIcon fontSize="small" />
              </Button>
            </Grid>

            <Grid item>
              <Button
                size="small"
                variant="contained"
                component="span"
                color="secondary"
                onClick={onAdd}
              >
                New Category
                <AddIcon fontSize="small" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
