import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import BallotIcon from '@material-ui/icons/Ballot'
import PeopleIcon from '@material-ui/icons/People'
import PageIcon from '@material-ui/icons/Pages'
import BuildIcon from '@material-ui/icons/Build'
import TerrainIcon from '@material-ui/icons/Terrain'
import SettingsApplications from '@material-ui/icons/SettingsApplications'
import CategoryIcon from '@material-ui/icons/Category'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { withStyles } from '@material-ui/core/styles'
import { drawerWidth } from './constants'
import withRoot from '../withRoot'
import { withDrawerContext } from '../contexts/drawer-context'

const styles = theme => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
})

const SideBar = ({ classes, onCloseDrawer, location, drawerContext }) => {
  const { open } = drawerContext
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClose
        ),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={onCloseDrawer}>
          <ChevronRightIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <SideBarItems pathname={location.pathname} />
      </List>
      <Divider />
    </Drawer>
  )
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withDrawerContext(
  withRouter(withRoot(withStyles(styles, { withTheme: true })(SideBar)))
)

export const SideBarItem = ({ icon, link, text, selected }) => {
  return (
    <ListItem button component={Link} to={link} selected={selected}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
}
export const SideBarItems = ({ pathname }) => {
  return (
    <Fragment>
      {SIDE_BAR_ITEMS.map(({ icon, link, text }) => {
        return (
          <SideBarItem
            key={text}
            {...{ icon, link, text }}
            selected={link === pathname}
          />
        )
      })}
    </Fragment>
  )
}

const SIDE_BAR_ITEMS = [
  { text: 'Episodes', link: '/episodes', icon: <BallotIcon /> },
  { text: 'Series', link: '/series', icon: <BallotIcon /> },
  { text: 'Maps', link: '/maps', icon: <TerrainIcon /> },
  { text: 'Levels', link: '/levels', icon: <PeopleIcon /> },
  { text: 'Onboarding', link: '/onboardings', icon: <PageIcon /> },
  { text: 'Features', link: '/features', icon: <BuildIcon /> },
  { text: 'Settings', link: '/settings', icon: <SettingsApplications /> },
  {
    text: 'Local Notification',
    link: '/local-notification',
    icon: <SettingsApplications />,
  },
  {
    text: 'Manage Category',
    link: '/categories',
    icon: <CategoryIcon />,
  },
]
