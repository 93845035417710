import { Paper, Typography, withStyles } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import CategoryForm from '../../components/categories/CategoryForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { CategoryAddStyle as styles } from './CategoriesStyle'

const CategoryAdd = props => {
  const { enqueueSnackbar, classes } = props
  const history = useHistory()
  const [category, setCategory] = useState({})

  const getSaveRequest = () => {
    const path = '/categories'
    const method = 'POST'
    const body = JSON.stringify(category)
    return Helper.ApiRequest(path, { method, body })
  }

  const onSuccess = () => {
    history.push('/categories')
  }

  const saveCategory = parentCategories => {
    if (!category.title) {
      enqueueSnackbar('Category Name is required', { variant: 'error' })
      throw new Error('Category Name is required')
    }
    if (
      parentCategories.find(
        parentCategory =>
          parentCategory.reference ===
          category.title
            .trim()
            .replace(/\s+/gim, '_')
            .toLowerCase()
      )
    ) {
      enqueueSnackbar('Category Name already exists', { variant: 'error' })
      throw new Error('Category Name already exists')
    }
    const promise = getSaveRequest()
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Successfully created category.',
      fail: 'Failed to create category',
      onSuccess,
    })
  }

  const handleChange = data => {
    setCategory(prevData => ({ ...prevData, ...data }))
  }

  return (
    <>
      <Typography component="a" variant="h4" align="center">
        CREATE NEW CATEGORY
      </Typography>
      <Paper className={classes.root}>
        <CategoryForm
          onChange={handleChange}
          onSave={saveCategory}
          category={category}
        />
      </Paper>
    </>
  )
}

export default withSnackbar(withRouter(withStyles(styles)(CategoryAdd)))
