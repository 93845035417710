const commonStyle = theme => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
})

export const OnboardingAddStyle = theme => ({
  ...commonStyle(theme),
})

export const OnboardingsStyle = theme => ({
  ...commonStyle(theme),
  container: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
})

export const OnboardingDetailStyle = theme => ({
  ...commonStyle(theme),
})
