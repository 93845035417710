import React from 'react'
import { Checkbox, TablePagination } from '@material-ui/core'
import { useHistory, withRouter } from 'react-router-dom'
import MaterialTable from 'material-table'
import { DeleteBook } from './Delete'

const pageTitle = 'Books'

const columns = [
  { title: 'Title', field: 'title' },
  { title: 'Ref', field: 'referenceSource' },
  { title: 'Free', field: 'isFree', type: 'boolean' },
  {
    title: 'Published',
    field: 'state',
    type: 'boolean',
    render: rowData => (
      <Checkbox checked={rowData.state === 'published'} disabled />
    ),
  },
]

const options = {
  sorting: true,
  columnsButton: true,
  exportButton: true,
  // filtering: true,
  emptyRowsWhenPaging: false,
  pageSize: 10,
  actionsColumnIndex: -1,
}

// The pagination component is not right with rtl
const components = {
  Pagination: props => (
    <TablePagination {...props} style={{ direction: 'ltr' }} />
  ),
}

const BookTable = props => {
  const { books, onDelete } = props
  const [deleteBook, setDeleteBook] = React.useState(null)
  const history = useHistory()

  const handleEdit = (e, rowData) => {
    history.push(`/books/${rowData._id}`)
  }
  const handleDelete = (e, rowData) => {
    setDeleteBook(rowData)
  }
  const handleAfterDelete = () => {
    setDeleteBook(null)
    if (onDelete) onDelete()
  }

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit book',
      onClick: handleEdit,
    },
    {
      icon: 'delete',
      tooltip: 'Delete book',
      onClick: handleDelete,
    },
  ]

  return (
    <>
      <MaterialTable
        title={pageTitle}
        columns={columns}
        data={books}
        options={options}
        components={components}
        actions={actions}
      />
      <DeleteBook
        book={deleteBook}
        forceOpen={!!deleteBook}
        onClose={handleAfterDelete}
        onAfterDelete={handleAfterDelete}
      />
    </>
  )
}

export default withRouter(BookTable)
