const Config = {
  apiURL: process.env.REACT_APP_API_URL,
  enableLog: false,
  sessionTimeout: 3600000, // one hour = 3600 000 ms
  localStorage: {
    keyLength: 64,
    keyName: process.env.REACT_APP_LS_KEY_NAME,
    tokenIndex: process.env.REACT_APP_LS_TOKEN_INDEX,
    whoamiIndex: process.env.REACT_APP_LS_WHOAMI_INDEX,
  },
  allowedRoles: process.env.REACT_APP_ALLOWED_ROLES.split(','),
  source: process.env.REACT_APP_SOURCE,
  minioBucket: process.env.REACT_APP_MINIO_BUCKET,
}

export default Config
