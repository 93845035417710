import { Grid } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { withPaths } from '../../contexts/data-context'
import Helper from '../../utils/Helper'
import { assetServer, checkUrlIsImage } from '../../utils/url'
import styles from './OnboardingFormStyle'
import ButtonSection from './ButtonSection'
import FilePreview from './FilePreview'
import TextField from './TextField'
import UploadField from './UploadField'

const OnboardingForm = props => {
  const { classes, onboarding, onChange, onSave, history, editMode } = props
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fileUrl, setFileUrl] = useState(null)

  useEffect(() => {
    if (onboarding.fileUrl) {
      setFileUrl(onboarding.fileUrl)
    }
  }, [onboarding])

  const handleFileUpload = event => {
    if (fileUrl) {
      URL.revokeObjectURL(fileUrl)
    }
    if (editMode) {
      onChange({ fileUrl: URL.createObjectURL(event.target.files[0]) })
    }
    setFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
  }

  const handleUploadImage = async () => {
    const uploadName = 'thumbs/' + file.name
    const formData = new FormData()
    formData.append('upload', file)
    await Helper.uploadAsset(uploadName, formData)
    return assetServer + uploadName
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleSave = async e => {
    // We don't want submit to refresh the page
    e.preventDefault()
    try {
      setLoading(true)
      if (file) onboarding.fileUrl = await handleUploadImage()
      onChange(onboarding)
      onSave()
    } catch (e) {
      setLoading(false)
    }
  }

  const handleChange = field => event => {
    if (onboarding && onboarding[field] !== undefined) {
      onboarding[field] = event.target.value
      onChange(onboarding)
    }
  }

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid
        className={classes.container}
        container
        spacing={8}
        direction="column"
        alignItems="center"
      >
        {file ? (
          <FilePreview
            image={file.type.includes('image')}
            fileUrl={fileUrl || ''}
            classes={classes}
          />
        ) : (
          onboarding.fileUrl && (
            <FilePreview
              image={checkUrlIsImage(onboarding.fileUrl)}
              fileUrl={onboarding.fileUrl || ''}
              classes={classes}
            />
          )
        )}
        <UploadField
          classes={classes}
          onChange={handleFileUpload}
          editMode={editMode}
        />

        <TextField
          classes={classes}
          onChange={handleChange('title')}
          value={onboarding.title}
          label="Add screen title"
          placeholder="Title"
        />
        <TextField
          classes={classes}
          onChange={handleChange('deepLink')}
          value={onboarding.deepLink}
          label="Add deep link"
          placeholder="Firebase Dynamic Link"
        />
        <ButtonSection
          classes={classes}
          onCancel={handleCancel}
          onSave={handleSave}
          editMode={editMode}
          loading={loading}
        />
      </Grid>
    </form>
  )
}

export default withPaths(withRouter(withStyles(styles)(OnboardingForm)))
