import React, { memo } from 'react'
import ChapterField from './ChapterField'
import EpisodeField from './EpisodeField'

const ReferenceField = memo(
  ({ classes, onChange, reference, referenceSource, chapters, episodes }) => {
    const props = {
      id: reference ? reference._id : '',
      onChange,
      classes,
    }
    switch (referenceSource) {
      case 'chapters':
        return <ChapterField chapters={chapters} {...props} />
      case 'episodes':
        return <EpisodeField episodes={episodes} {...props} />
      default:
        return null
    }
  }
)

export default ReferenceField
