import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Tabs from '@material-ui/core/Tabs/Tabs'
import Tab from '@material-ui/core/Tab'
import AddIcon from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import { withStyles, withTheme } from '@material-ui/core/styles'
import Helper from '../../utils/Helper'
import { withDrawerContext } from '../../contexts/drawer-context'
import { Paper, Fab } from '@material-ui/core'
import EpisodeForm from '../../components/EpisodeForm'
import ChapterTable from '../../components/ChapterTable'
import { withSnackbar } from 'notistack'
import { doAndSnack } from '../../utils/snackbar'
import GameTable from '../../components/GameTable'
import { move } from '../../utils/array'
import BookTable from '../../components/BookTable'

const tabs = ['details', 'chapters', 'games', 'books']
const styles = theme => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
  },
  fab: {
    top: 'auto',
    right: '3rem',
    bottom: '2rem',
    left: 'auto',
    position: 'fixed',
  },
})

class EpisodeDetail extends React.Component {
  constructor(props) {
    super(props)
    const { match } = props
    const tabIndex = tabs.findIndex(v => v === match.params.tab)
    const tabValue = tabIndex === -1 ? 0 : tabIndex
    this.state = {
      episode: null,
      tabValue,
      isLoading: false,
      timeout: null,
    }
  }
  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    const { drawerContext, theme } = this.props

    if (drawerContext.open !== prevProps.drawerContext.open) {
      // We execute updateIndicator two times, one at the begining of the
      // animation and one at the end
      // because the calculation will not be exact if it only done at the begining
      // and if it is only at the end there is time where the indicator is
      // out of the screen
      this.updateIndicator()
      const timeout = setTimeout(
        this.updateIndicator,
        theme.transitions.duration.leavingScreen
      )
      this.setState({ timeout })
    }
  }

  // In case we unmount and the timeout is not executed we cancel it
  componentWillUnmount() {
    clearTimeout(this.state.timeout)
  }

  fetchData = async () => {
    const { slug } = this.props.match.params
    const [episode] = await Helper.ApiRequest(
      `/episodes?slug=${slug}&populates=activities`
    )
    const title = getEpisodeTitle(episode)
    this.setState({ episode, title })

    // We cannot take episodes.activities because it is not up to date
    const activities = await Helper.ApiRequest(
      `/episodes/${episode._id}/activities`
    )

    this.setState({ activities })
  }

  handleChangeTab = (e, tabValue) => {
    const { history } = this.props
    const { episode } = this.state

    history.push(`/episodes/${episode.slug}/${tabs[tabValue]}`)
    this.setState({ tabValue })
  }

  // This is used to update the tab indicator when the side bar opens
  getAction = actions => {
    this.updateIndicator = actions.updateIndicator
  }

  handleUpChapter = chapter => {
    this.moveChapter(chapter._id, -1)
  }
  handleDownChapter = chapter => {
    this.moveChapter(chapter._id, 1)
  }

  moveChapter = (id, diff) => {
    const { episode } = this.state
    const { chapters } = episode
    const index = chapters.findIndex(c => id === c._id)
    const newIndex = index + diff
    if (newIndex < 0 || newIndex >= chapters.length) return
    episode.chapters = move(index, newIndex, ...chapters)
    this.setState({ episode }, this.saveEpisode)
  }

  saveEpisode = () => {
    const { tabValue, episode } = this.state
    const { history, enqueueSnackbar: snack } = this.props
    const slug = episode.slug


    if (!episode.series) {
      episode.series = null
    } else if (
      episode.path._id !== (episode.series.path._id || episode.series.path)
    ) {
      episode.series = null
    }

    const promise = Helper.ApiRequest(`/episodes/${episode._id}`, {
      method: 'PUT',
      body: JSON.stringify(episode),
    })
    const onSuccess = () => {
      history.push(`/episodes/${slug}/${tabs[tabValue]}`)
      this.fetchData()
    }
    doAndSnack(promise, snack, {
      success: 'Episode succesfully saved',
      fail: 'Failed to save episode',
      onSuccess,
    })
  }
  handleAddChapter = () => {
    const { episode } = this.state
    const { history } = this.props
    history.push(`/chapters/add?episode=${episode.slug}`)
  }
  handleDeleteChapter = () => {
    this.fetchData()
  }
  handleChange = episode => {
    this.setState({ episode })
  }

  handleAddGame = () => {
    const { episode } = this.state
    const { history } = this.props
    history.push(`/games/add?episode=${episode.slug}`)
  }

  handleAddBook = () => {
    const { episode } = this.state
    const { history } = this.props
    history.push(`/books/add?episode=${episode.slug}`)
  }

  render() {
    const { classes } = this.props
    const { episode, title, tabValue, activities } = this.state
    if (!episode) return null
    const { chapters } = episode || {}
    return (
      <React.Fragment>
        <Typography component="h1" variant="h4" align="center">
          {title}
        </Typography>
        <Paper className={classes.paper}>
          <Tabs
            value={tabValue}
            onChange={this.handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            action={this.getAction}
          >
            <Tab label="Details" />
            <Tab label="Chapters" />
            <Tab label="Games" />
            <Tab label="Book" />
          </Tabs>

          {tabValue === 0 && (
            <TabContainer>
              <EpisodeForm
                episode={episode}
                onChange={this.handleChange}
                onSave={this.saveEpisode}
              />
            </TabContainer>
          )}
          {tabValue === 1 && (
            <ChaptersTab
              chapters={chapters}
              onAdd={this.handleAddChapter}
              onDelete={this.handleDeleteChapter}
              onUp={this.handleUpChapter}
              onDown={this.handleDownChapter}
            />
          )}
          {tabValue === 2 && (
            <GamesTab
              games={
                activities
                  ? activities.filter(({ category }) => category === 'game')
                  : []
              }
              onAdd={this.handleAddGame}
              onDelete={this.handleDeleteChapter}
            />
          )}
          {tabValue === 3 && (
            <BooksTab
              books={
                Array.isArray(activities) && activities
                  ? activities.filter(({ category }) => category === 'book')
                  : []
              }
              onAdd={this.handleAddBook}
              onDelete={this.handleDeleteChapter}
            />
          )}
        </Paper>
      </React.Fragment>
    )
  }
}

EpisodeDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(
  withDrawerContext(withRouter(withTheme()(withStyles(styles)(EpisodeDetail))))
)

function getEpisodeTitle({ level, slug, order }) {
  return `Episode ${level}.${order} : ${slug}`
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

const ChaptersTab = withStyles(styles)(
  ({ chapters, classes, onAdd, onDelete, onUp, onDown }) => {
    return (
      <TabContainer>
        <ChapterTable
          data={chapters}
          onDelete={onDelete}
          onUp={onUp}
          onDown={onDown}
        />
        <Fab
          color="secondary"
          aria-label="Add"
          className={classes.fab}
          onClick={onAdd}
        >
          <AddIcon />
        </Fab>
      </TabContainer>
    )
  }
)

const GamesTab = withStyles(styles)(({ games, classes, onAdd, onDelete }) => {
  return (
    <TabContainer>
      <GameTable data={games} onDelete={onDelete} />
      <Fab
        color="secondary"
        aria-label="Add"
        className={classes.fab}
        onClick={onAdd}
      >
        <AddIcon />
      </Fab>
    </TabContainer>
  )
})

const BooksTab = withStyles(styles)(({ books, classes, onAdd, onDelete }) => {
  return (
    <TabContainer>
      <BookTable books={books} onDelete={onDelete} />
      <Fab
        color="secondary"
        aria-label="Add"
        className={classes.fab}
        onClick={onAdd}
      >
        <AddIcon />
      </Fab>
    </TabContainer>
  )
})

// const MapTab = withStyles(styles)(({ map, classes, onAdd, onDelete }) => {
//   return (
//     <TabContainer>
//       <MapForm map={map} />
//     </TabContainer>
//   )
// })
