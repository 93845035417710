import React, { useEffect, useState } from 'react'
import { Paper, Typography, withStyles } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import LinkIcon from '@material-ui/icons/Link'
import { Link, useHistory, useParams, withRouter } from 'react-router-dom'
import { withEpisodes } from '../../contexts/data-context'

import { BookDetailStyle as styles } from './BookStyle'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import BookForm from '../../components/book/BookForm'

const BookDetail = props => {
  const [book, setBook] = useState(null)
  const { id } = useParams()
  const history = useHistory()

  const { enqueueSnackbar, classes } = props

  const fetchData = async () => {
    const book = await Helper.ApiRequest(
      `/activities/${id}?$select[]=category&$select[]=content&$select[]=description&$select[]=isFree&$select[]=reference&$select[]=referenceSource&$select[]=state&$select[]=thumbnail&$select[]=title&$select[]=_id`
    )
    setBook(book)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onSuccess = () => {
    const { _id } = book
    history.push(`/books/${_id}`)
    fetchData()
  }

  const getSaveRequest = async book => {
    const { _id } = book
    const path = `/activities/${_id}`
    const method = 'PUT'
    const body = JSON.stringify(book)
    return await Helper.ApiRequest(path, { method, body })
  }

  const handleSave = async () => {
    const promise = await getSaveRequest(book)
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Book succesfully saved',
      fail: 'Failed to save book',
      onSuccess,
    })
  }

  const handleChange = book => {
    setBook({ ...book })
  }

  return (
    <>
      {book && (
        <>
          <FormTitle
            reference={book.reference}
            referenceSource={book.referenceSource}
          />
          <Paper className={classes.paper}>
            <BookForm book={book} onChange={handleChange} onSave={handleSave} />
          </Paper>
        </>
      )}
    </>
  )
}

BookDetail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(
  withEpisodes(withRouter(withStyles(styles)(BookDetail)))
)

function FormTitle({ reference, referenceSource }) {
  const { title, slug } = reference || {}
  let refName, link
  switch (referenceSource) {
    case 'episodes':
      refName = 'Episode'
      link = `/episodes/${slug}/books`
      break
    case 'chapters':
      refName = 'Chapter'
      link = `/chapters/${slug}`
      break
    default:
      return null
  }
  return (
    <Typography component="h2" variant="h4" align="center">
      {refName} Title : {title}
      <Link to={link}>
        <LinkIcon />
      </Link>
    </Typography>
  )
}
