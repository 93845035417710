import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import ChapterForm from '../../components/chapter/ChapterForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'
import { ChapterAddStyle as styles } from './ChapterStyles'

const ChapterAdd = props => {
  const { classes, enqueueSnackbar } = props
  const [chapter, setChapter] = useState({})
  const history = useHistory()

  const fetchData = async () => {
    const { search } = history.location
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    })
    const { episode: episodeParam } = params

    const [episode] = episodeParam
      ? await Helper.ApiRequest(`/episodes?slug=${episodeParam}`)
      : []
    chapter.episode = episode
    setChapter(chapter)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const saveChapter = async () => {
    const [promise, onSuccess] = await getSaveRequest(chapter)
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Chapter succesfully saved',
      fail: 'Failed to save chapter',
      onSuccess,
    })
  }

  const getSaveRequest = async data => {
    const epslug = data.episode.slug
    const path = '/chapters'
    const method = 'POST'
    const body = JSON.stringify(data)
    const promise = await Helper.ApiRequest(path, {
      method,
      body,
    })

    const onSuccess = () => {
      history.push(`/episodes/${epslug}/chapters`)
    }

    return [promise, onSuccess]
  }

  const handleChange = data => {
    setChapter({
      ...data,
    })
  }

  return (
    <>
      <FormTitle />
      <Paper className={classes.paper}>
        <ChapterForm
          onChange={handleChange}
          onSave={saveChapter}
          chapter={chapter}
        />
      </Paper>
    </>
  )
}

ChapterAdd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withRouter(withStyles(styles)(ChapterAdd)))

function FormTitle() {
  return (
    <Typography component="a" variant="h4" align="center">
      Add a chapter
    </Typography>
  )
}
