import React, { memo } from 'react'
import { FormControl, FormControlLabel, Switch } from '@material-ui/core'

const IsFreeField = memo(({ classes, isFree, handleChange }) => {
  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel
        control={
          <Switch
            checked={isFree}
            onChange={handleChange('isFree')}
            value="isFree"
          />
        }
        label="Free"
      />
    </FormControl>
  )
})

export default IsFreeField
