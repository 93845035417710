import React from 'react'

export const DrawerContext = React.createContext({ open: true })

export const withDrawerContext = Element =>
  React.forwardRef((props, ref) => (
    <DrawerContext.Consumer>
      {context => <Element drawerContext={context} {...props} ref={ref} />}
    </DrawerContext.Consumer>
  ))
