import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import EpisodeForm from '../../components/EpisodeForm'
import Helper from '../../utils/Helper'
import { doAndSnack } from '../../utils/snackbar'

const styles = theme => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
})

class EpisodeAdd extends React.Component {
  state = {
    episode: {},
  }

  saveEpisode = async () => {
    const { history, enqueueSnackbar } = this.props
    const { episode } = this.state
    const { slug } = episode
    const promise = Helper.ApiRequest(`/episodes/`, {
      method: 'POST',
      body: JSON.stringify(episode),
    })
    doAndSnack(promise, enqueueSnackbar, {
      success: 'Episode succesfully saved',
      fail: 'Failed to save episode',
      onSuccess: () => history.push(`/episodes/${slug}/details`),
    })
  }

  handleChange = episode => {
    this.setState({ episode })
  }
  render() {
    const { classes } = this.props
    const { episode } = this.state
    return (
      <React.Fragment>
        <Typography component="h1" variant="h4" align="center">
          Add a new episode
        </Typography>
        <Paper className={classes.paper}>
          <EpisodeForm
            episode={episode}
            onChange={this.handleChange}
            onSave={this.saveEpisode}
          />
        </Paper>
      </React.Fragment>
    )
  }
}

EpisodeAdd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withSnackbar(withRouter(withStyles(styles)(EpisodeAdd)))
